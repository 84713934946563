import { consume } from '@lit/context';
import { Task } from '@lit/task';
import { HttpClient } from 'aurelia-fetch-client';
import { clientContext } from 'context/client-context';
import { SortOrder } from 'controllers/storage-order-controller';
import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { baseStyles, flexStyles, gridStyles } from 'pli';
import { TMSResponse } from 'response-types/base-response';
import { getFormattedLocalTime } from 'utils/datetime-formatter';
import { nameFormatter } from 'utils/name-formatter';

import '../../pli/pli-card';
import '../../pli/pli-search-form';
import '../../pli/pli-skeleton';
import '../../pli/pli-text';
import '../../pli/pli-status-label';
import '../../pli/pli-table';
import '../../pli/pli-pagination';
import '../../pli/pli-user-bubble-list';
import { defineHeaderItems } from '../../pli/pli-table';
import '../../pli/pli-illustration';
import { ReportFilingStatus } from './schema';
import '../../pli/pli-pager';

type ReportFilingsResponse = TMSResponse<ReportFiling>;

type ReportFiling = {
    reportFilingId: string;
    filingName: string;
    reportCode: string;
    createdUtc: Date;
    customer: {
        name: string;
    };
    partyLastName: string;
    status: ReportFilingStatus;
    assigned: Assigned[];
};

type Assigned = {
    userId: string;
    firstName: string;
    lastName: string;
    fullName: string;
};

type QueryPayload = {
    page: number;
    sortField: string | null;
    sortOrder: string | null;
};

@customElement('report-filings-overview')
class ReportFilingsOverview extends LitElement {
    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    public client: HttpClient;

    static styles = [
        baseStyles,
        gridStyles,
        flexStyles,
        css`
            .gap-05 {
                gap: var(--size-0-5);
            }

            .to-case-button {
                --negative-offset: calc(var(--size-0-75) * -1);
                margin-left: var(--negative-offset);
            }
        `,
    ];

    @state()
    _page = 1;
    @state()
    _sortOrder: SortOrder | null = null;
    @state()
    _sortField: string | null = null;
    @state()
    _total = 0;

    headerItems = defineHeaderItems({
        Id: { sortField: null, columnSpan: 1 },
        Name: { sortField: null, columnSpan: 2 },
        Customer: { sortField: null, columnSpan: 2 },
        Status: { sortField: null, columnSpan: 2 },
        Created: { sortField: 'CreatedUtc', columnSpan: 2 },
        Type: { sortField: null, columnSpan: 1 },
        Assigned: { sortField: null, columnSpan: 1 },
    });
    _items: ReportFiling[] = [];

    private _task = new Task(this, {
        task: async ([page, sortField, sortOrder]) => {
            const query = this.buildQuery({ page, sortField, sortOrder });
            const url = `report-filings?${query}`;
            const response = await this.client.get(url);
            const data = (await response.json()) as ReportFilingsResponse;
            this._total = data.total;
            this._items = [...data.list];

            return data;
        },
        args: () => [this._page, this._sortField, this._sortOrder] as const,
    });

    buildQuery(payload: QueryPayload): string {
        const { sortField, sortOrder, page } = payload;

        const searchParams = new URLSearchParams();
        
        if (page)
            searchParams.append('page', page.toString());

        if (sortField)
            searchParams.append('sortField', sortField);

        if (sortOrder)
            searchParams.append('sortOrder', sortOrder);

        return searchParams.toString();
    }

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    private _sortHandler(event: CustomEvent) {
        this._sortField = event.detail.field;
        this._sortOrder = event.detail.order;
        event.stopPropagation();
    }

    renderItem(item: ReportFiling): TemplateResult {
        return html`
            <tr>
                <td><strong>${item.reportFilingId}</strong></td>
                <td>
                    <a data-link="navigate" href="report-filing/${item.reportFilingId}">${item.filingName}</a>
                </td>
                <td>${item.customer?.name}</td>
                <td>
                    <pli-status-label variant="${item.status}">${item.status}</pli-status-label>
                </td>
                <td>${getFormattedLocalTime(item.createdUtc)}</td>
                <td>${item.reportCode}</td>
                <td>
                    <pli-user-bubble-list .items="${item.assigned}"></pli-user-bubble-list>
                </td>
            </tr>
        `;
    }

    render() {
        return html`
            <pli-card>
                <div class="grid">
                    <div class="col-span-8">
                        <pli-text variant="h2" as="h2" slot="title">Reports</pli-text>
                    </div>
                </div>
                <pli-pager
                    .items="${this._items}"
                    page="${this._page}"
                    total="${this._total}"
                    @page-update="${this._paginationHandler}"
                    ><pli-table
                        @sort="${this._sortHandler}"
                        .headerItems="${this.headerItems}"
                        .items="${this._items}"
                        .renderTemplate="${this.renderItem}"
                    ></pli-table
                ></pli-pager>
            </pli-card>
        `;
    }
}
