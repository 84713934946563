import { DocumentModel } from 'document-model';
import { ReportFilingStatus } from 'report-filing/report-filing-status';

export class ReportFiling {
    reportFilingId: string;
    customerId: string;
    filingName: string;
    description: string;
    reportingCustomerId: string;
    reportCode: string;
    reportType: string;
    localCurrencyCode: string;
    submissionDate: string;
    submissionDateUtc?: Date;
    agentReportingUserCode: string;
    addressType: string;
    address: string;
    city: string;
    countryCode: string;
    reason: string;
    actions: string;
    status: ReportFilingStatus;
    customer: CustomerReport;
    suspiciousIndicators: string[];
    riskIndicators: string[];
    reportIndicatorTypes: string[];
    suspiciousIndicatorsValidator: string;
    riskIndicatorsValidator: string;
    documents: DocumentModel[];
    case: CaseReport;
}

export type Indicator = {
    name: string;
    description: string;
    selected: boolean | null;
};

export class CaseReport {
    caseId: number;
    name: string;
}

export class CustomerReport {
    type: string;
    firstName: string;
    lastName: string;
    birthdate: string;
}
