import { z } from 'zod';
import { legalFormsEnum } from 'shared/schema/legal-form-schema';

export type ReportFilingDetailsDocument = z.infer<typeof docSchema>;

const _requiredStringValue = (message: string = 'Required field') =>
    z.string({ invalid_type_error: message }).min(1, { message });
const _requiredNumberValue = (message: string = '') => z.number().positive({ message });
const _optionalStringValue = z.string().optional().nullable();

const docSchema = z.object({
    documentId: z.number(),
    uploadedTime: _requiredStringValue(),
    uploadedById: _requiredStringValue(),
    uploadedBy: z.object({
        userId: _requiredStringValue(),
        firstName: _requiredStringValue(),
        lastName: _requiredStringValue(),
        fullName: _requiredStringValue(),
    }),
    name: _requiredStringValue(),
    contentType: _requiredStringValue(),
});

export const SUSPICIOUS_INDICATORS = ['BMTTF', 'AMTPT'] as const;
export const suspiciousIndicatorsEnum = z.enum(SUSPICIOUS_INDICATORS);
export type SuspiciousIndicator = z.infer<typeof suspiciousIndicatorsEnum>;

export const RISK_INDICATORS = [
    'GEO01',
    'GEO02',
    'GEO03',
    'GEO04',
    'INF01',
    'INF02',
    'INF03',
    'INF04',
    'INF05',
    'INF06',
    'KAN01',
    'KAN02',
    'KAN03',
    'KDK01',
    'KDK02',
    'KDK03',
    'KDK04',
    'KDK05',
    'KDK06',
    'KDK07',
] as const;
export const riskIndicatorsEnum = z.enum(RISK_INDICATORS);
export type RiskIndicator = z.infer<typeof riskIndicatorsEnum>;

const indicatorsEnum = z.intersection(riskIndicatorsEnum, suspiciousIndicatorsEnum);

const REPORT_FILING_STATUS = ['InProgress', 'Reported'] as const;
export const reportFilingStatus = z.enum(REPORT_FILING_STATUS);
export type ReportFilingStatus = z.infer<typeof reportFilingStatus>;

export const availableCurrencies = ['EUR', 'SEK', 'USD'] as const;
export const currencyEnum = z.enum(availableCurrencies);

export const mainSchema = z.object({
    filingName: _requiredStringValue('Name is required'),
    description: _optionalStringValue,
    reportingCustomerId: _requiredNumberValue('goAML reporting customer ID is required'),
    localCurrencyCode: currencyEnum,
    submissionDateUtc: _requiredStringValue().datetime(
        'Invalid date. Submission date needs to follow format: YYYY-MM-DD',
    ),
    agentReportingUserCode: _requiredStringValue('Reporting user code is required.').max(50, {
        message: 'Reporting user code can be max 50 characters.',
    }),
    address: _requiredStringValue(),
    reportFilingId: _requiredNumberValue(),
    reason: _requiredStringValue('Reason for reporting is required.').max(4000, {
        message: 'Reason for reporting can be max 4000 characters.',
    }),
    customerId: _requiredStringValue(),
    reportCode: _requiredStringValue(),
    reportType: _requiredStringValue(),
    addressType: _requiredStringValue(),
    city: _requiredStringValue(),
    countryCode: _requiredStringValue(),
    actions: _requiredStringValue('Actions taken or planned is required.').max(4000, {
        message: 'Actions taken or planned can be max 4000 characters.',
    }),
    status: reportFilingStatus,
    customer: z.object({
        type: _requiredStringValue(),
        personCustomer: z.object({            
            firstName: _optionalStringValue,
            lastName: _optionalStringValue,
            birthdate: _optionalStringValue,
        }),
        organizationCustomer: z.object({
            type: _optionalStringValue,
            name: _optionalStringValue,
            incorporationNumber: _optionalStringValue,
            incorporationLegalForm: legalFormsEnum,
        }),
    }),
    
    reportIndicatorTypes: z.array(indicatorsEnum),
    documents: z.array(docSchema).optional(),
    case: z.object({
        caseId: z.number(),
        name: _requiredStringValue(),
    }),
});

//For being able to validate risk and suspicious indicators separately in form
export const indicatorsSchema = z.object({
    riskIndicators: z.array(riskIndicatorsEnum).min(1, { message: 'At least one risk indicator is required.' }),
    suspiciousIndicators: z
        .array(suspiciousIndicatorsEnum)
        .min(1, { message: 'At least one suspicious indicator is required.' }),
});
export const indicatorsSchemaKey = indicatorsSchema.keyof();
export const localPayloadSchema = mainSchema.omit({ reportIndicatorTypes: true }).merge(indicatorsSchema);
export const keys = localPayloadSchema.keyof();
export const localPayloadPartial = localPayloadSchema.partial();

export type SchemaKey = z.infer<typeof keys>;
export type IndicatorsSchemaKey = z.infer<typeof indicatorsSchemaKey>;
export type ReportFilingsDetailReponse = z.infer<typeof mainSchema>;
export type ReportFilingsDetailPayload = z.infer<typeof localPayloadSchema>;
export type Payload = z.infer<typeof localPayloadPartial>;
