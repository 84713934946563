import { consume } from '@lit/context';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styles } from 'pli/styles';
import { reportFilingSavedDateContext } from '../context/report-filings-saved-context';
import { reportFilingStatus } from '../schema';
import { ReportFilingFormSection } from '../mixin';

import '../../../pli/pli-button';
import '../../../pli/pli-icon';
import '../../../pli/pli-input';

@customElement('report-filing-details-button-group')
class RenderButtonGroup extends ReportFilingFormSection {
    static styles = [
        styles.grid,
        styles.flex,
        css`
            .button-group {
                top: var(--button-group-top);
                align-self: flex-start;
            }
            .saved-date-wrapper {
                opacity: 0;
                margin-top: var(--saved-date-wrapper-negative-offset);
                text-align: center;
                padding: var(--size-0-5) 0;
                transition: opacity 150ms ease;
            }
            .visible {
                opacity: 1;
            }

            ul,
            li {
                padding: 0;
            }
        `,
    ];
    @property()
    onDownload: () => void;

    @property()
    onClose: () => void;

    @property()
    onReopen: () => void;

    @property()
    onDelete: () => void;

    @consume({ context: reportFilingSavedDateContext, subscribe: true })
    savedDate: string;

    @state()
    _showErrorsEnabled = false;

    render() {
        const { _showErrorsEnabled, savedDate, onDownload, onClose, onReopen, onDelete, payload, issues } = this;
        const savedDateWrapperClasses = classMap({
            'saved-date-wrapper': true,
            visible: Boolean(savedDate),
        });

        const onDownloadCLick = () => {
            this._showErrorsEnabled = true;
            onDownload();
        };

        const onCloseClick = () => {
            this._showErrorsEnabled = true;
            onClose();
        };

        const showErrors = _showErrorsEnabled && Boolean(issues?.length);
        return html`<div class="">
            <div class="${savedDateWrapperClasses}">Saved: ${savedDate}</div>
            <div class="grid-vertical gap-05">
                <pli-button size="lg" width="full" .onClick="${onDownloadCLick}">
                    <pli-icon slot="icon-left" name="file-earmark-arrow-down"></pli-icon>
                    Download report
                </pli-button>
                ${payload?.status === reportFilingStatus.enum.InProgress
                    ? html`<pli-button size="lg" width="full" .onClick="${onCloseClick}" variant="secondary">
                          <pli-icon slot="icon-left" name="check2-circle"></pli-icon>
                          Confirm as reported
                      </pli-button> `
                    : html`<pli-button size="lg" width="full" .onClick="${onReopen}" variant="secondary">
                          <pli-icon slot="icon-left" name="arrow-counterclockwise"></pli-icon>
                          Reopen report
                      </pli-button>`}
                <pli-button size="lg" width="full" variant="destructive" .onClick="${onDelete}">
                    <pli-icon slot="icon-left" name="trash"></pli-icon>
                    Delete report
                </pli-button>
                ${showErrors
                    ? html`
                          <pli-alert-box variant="error">
                              <div>
                                  <pli-text>Report needs to be valid before downloadning.</pli-text>
                                  <ul class="grid-vertical gap-1">
                                      ${issues.map((issue) => html`<li>${issue.message}</li>`)}
                                  </ul>
                              </div>
                          </pli-alert-box>
                      `
                    : null}
            </div>
        </div>`;
    }
}
